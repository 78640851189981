import React from 'react'
import igrid from '../../assets/images/ig_logo_small.svg'
import AvenueImg from '../../components/AvenueImg'
import { Col, NewsLink, Thumbnail, Description, Title } from './style'

const NewsTiles = ({ blogPost }) => {
  const { uri, featuredImage, date, title, excerpt, tags } = blogPost || {}

  return (
    <Col key={uri}>
      <NewsLink to={uri}>
        <Thumbnail>
          <AvenueImg fluid={featuredImage?.node} />

          <span>
            <img src={igrid} alt="" />
            <p>{date}</p>
            <p>|</p>
            <p>{tags.nodes[0]?.name || `Article`}</p>
          </span>
        </Thumbnail>

        <div>
          <Title>{title}</Title>
          <Description dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
      </NewsLink>
    </Col>
  )
}

export default NewsTiles
