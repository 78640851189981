import React from 'react'
import { media } from '../utils/style-utils'
import InternalHeader from '../components/internalHeader'
import { NewsTiles } from '../components/newsTiles'
import GetInTouch from '../components/getInTouch'
import { graphql } from 'gatsby'
import GlobalComponents from '../components/globalcomponents'
import styled from 'styled-components'
import BlogPostTile from '../components/blogPostTile'
import { Row } from '../utils/style-utils'

const NewsSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 30px;

  ${media.medium`
    grid-template-columns: 1fr 1fr;
    column-gap:30px;
    max-width: 1300px;
    margin:0 auto;
    `}
  ${media.large`
    column-gap:40px;
    grid-template-columns: 1fr 1fr 1fr;
    
  `}

  @media (min-width: 1600px) {
    max-width: 1480px;
  }
`

const Spacer = styled.div`
  @media (min-width: 1024px) {
    height: 50px;
  }
`

const IndexPage = ({ data }) => {
  return (
    <GlobalComponents>
      <InternalHeader
        title="The latest"
        description="“Working with Avenue was like going to a fine tailor and getting a beautiful custom suit made.”"
        flag="OUR BLOG"
        img="header_big_full_la7v02"
        template="background"
        margin="margin"
        size="blogindex"
      />

      <NewsSection>
        {data.allWpPost.nodes.map((item) => {
          return <NewsTiles key={item.uri} blogPost={item} />
        })}

        {data.allMarkdownRemark.edges.map((cs) => {
          return <BlogPostTile key={cs.node.fields.slug} blogPost={cs.node} />
        })}
      </NewsSection>
      <Spacer />
      <GetInTouch
        left="In coffee we trust."
        center="The best ideas start with great coffee. You pick the spot, we’ll pick up the tab."
        right="Let's have a chat"
        action="/contact"
      />
    </GlobalComponents>
  )
}

export default IndexPage

export const query = graphql`
  {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            tileImage
            description
            blogTileStyle
            date(formatString: "DD MMM YY")
            tag
          }
          fields {
            slug
          }
        }
      }
    }
    allWpPost(sort: { order: DESC, fields: date }) {
      nodes {
        title
        uri
        excerpt
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
        date(formatString: "DD MMM YY")
      }
    }
  }
`
