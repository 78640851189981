import React from 'react'
import Link from './blogLink'

import igrid from '../assets/images/ig_logo_small.svg'

import Img from '../components/responsiveCloudinary'
import styles from './blogPostTile.module.scss'
import styled from 'styled-components'
import { media } from '../utils/style-utils'

const Col = styled.div`
  flex: 0 0 auto;
  padding-left: 29px;
  padding-right: 29px;
  flex-basis: 100%;
  max-width: 100%;

  ${media.medium`

    padding-left: 20px;
    padding-right: 20px;
  `}
`

export default ({ blogPost }) => {
  let destinationLink

  if (
    blogPost.frontmatter.blogTileStyle &&
    blogPost.frontmatter.blogTileStyle === 'igrid'
  ) {
    destinationLink = blogPost.frontmatter.url
  } else {
    destinationLink = blogPost.fields.slug
  }

  return (
    <Col key={blogPost.slug}>
      <Link
        className={`${styles.link} ${styles.block}`}
        key={blogPost.fields.slug}
        to={destinationLink}
      >
        <div className={styles.thumbnail}>
          <Img
            user="avenue"
            medium="50vw"
            large="454px"
            publicId={blogPost.frontmatter.tileImage}
            alt=""
          />
          <span className={styles[blogPost.frontmatter.blogTileStyle]}>
            <img src={igrid} alt="" />
            <p>{blogPost.frontmatter.date}</p>
            <p>|</p>
            <p>{blogPost.frontmatter.tag}</p>
          </span>
        </div>

        <div className={styles[blogPost.frontmatter.blogTileStyle]}>
          <h3 className={styles.title}>{blogPost.frontmatter.title}</h3>
          <p className={styles.description}>
            {blogPost.frontmatter.description}
          </p>
        </div>
      </Link>
    </Col>
  )
}
