import styled from 'styled-components'
import { media } from '../../utils/style-utils'
import Link from '../blogLink'

export const Col = styled.div`
  ${media.medium`
    padding: 0 20px;
  `}
`

export const Description = styled.p`
  text-decoration: none;

  ${media.medium`
    margin-bottom: 60px;
  `}
`
export const Title = styled.h3`
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  padding-right: 40px;
  line-height: 34px;
  color: #202020;
  margin-top: 22px;
  margin-bottom: 12px;

  ${media.medium`
    font-size: 36px;
    line-height: 42px;
    `}
`

export const NewsLink = styled(Link)`
  width: 100%;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  margin-bottom: 0;
  ${media.medium`
    margin-bottom: 10px;
   `} hover {
    ${Title} {
      text-decoration: underline;
    }
  }
`

export const Thumbnail = styled.div`
  position: relative;

  img {
    object-fit: contain;
  }

  span {
    position: absolute;
    bottom: -3px;
    left: 0;
    background: white;
    padding-right: 12px;
    padding-top: 3px;

    ${media.medium`
    padding-right: 18px;
    padding-top: 4px;
  `}

    img {
      display: none;
    }

    p {
      display: inline;
      font-weight: 500;
      font-size: 10px;
      color: #869599;
      letter-spacing: 1.5px;
      line-height: 18px;
      text-transform: uppercase;

      ${media.medium`
      font-size: 12px;
    `}
    }
    p:nth-child(3) {
      padding: 0 10px;
    }

    p:nth-child(3),
    p:nth-child(4) {
      font-size: 10px;
      color: #9d1864;
      letter-spacing: 1.5px;
      line-height: 18px;

      ${media.medium`
      font-size: 12px;
    `}
    }
  }
`
